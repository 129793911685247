
// This file contains all the NAVIGATION styles
// This file is imported into the _components.scss file

.fp-main-nav {
  @include flex(null, flex-end, null);
  @include size(100%, null);
  border-bottom: $border-primary;

  &__list {
    @include flex(flex-start, center, null);
  }

  &__list-item {
    @include padding($spacer * 2);
    @include margins(0 $spacer);
    @include flex(null, center, null);
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    position: relative;

    &:nth-child(2) {
     // @include margins(0 0 0 $spacer * 2);
    }

    a {
      color: $color-text--80;
    }

    span {
      @include size(100%, 5px);
      @include transition(0.15s, ease);
      background: $color-primary;
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__local {
    position: relative;
    &:after {
      @include size(1px, $spacer * 5);
      content: '';
      background: $color-primary;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
    }

    .fp-main-nav__list-item {
      @include margins(0 $spacer * 2 0 0);
    }
  }

  .active-list-item a{
    color: $color-primary;
  }
}