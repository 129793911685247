
// This file contains all the FOOTER styles
// This file is imported into the _components.scss file

.fp-footer {
  background: $white;
  @include space(padding, top, $spacer * 6);

  @media screen and (max-width: 1392px) {
    @include space(padding, all, 0 $spacer * 4);
  }

  &__header {
    @include padding(0 0 $spacer * 7 0);
    border-bottom: $border-light-gray;
    text-align: center;

    figure {
      @include size(100%, 30px);

      a {
        @include size(null, inherit);
        display: inline-block;
      }

      svg {
        @include size(auto, inherit);
      }
    }
  }

  &__body {
    @include flex();
    @include padding($spacer * 10 0);
    border-bottom: $border-light-gray;

    @media(max-width: $tablet) {
      @include flex(null, null, column);
    }
  }

  &__links {
    @include size(100%, null);
    @include grid(1fr 1fr, $spacer * 4, auto);
    @media(max-width: $mobile) {
      @include grid(1fr, $spacer * 6, auto);
    }

    h4 {
      color: $color-text--80;
      a {
        color: $color-text--80;
        &:visited {
          color: $color-text--80;
        }
      }
    }

    ul {
      @include flex($wrap: wrap);
    }

    li {
      @include margins(0 $spacer * 2 $spacer 0);

      &:last-child {
        @include margins(0);
      }

      a {
        @include font($text-sm, $font-regular, normal);
        color: $color-text--50;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media(max-width: $tablet) {
      @include margins(0 0 $spacer * 6 0);
    }
  }

  &__inner {
    grid-column: span 1;
  }

  &__social-media {
    @include margins($spacer * 6 0 0 0);
    @include flex(center, center, null);
    grid-column: span 2;
    @media(max-width: $mobile) {
      @include margins(0);
      grid-column: span 1;
      display: block;
    }
  }

  &__category {
    h4 {
      @include font($text-xs, $font-bold, normal);
      @include margins(0 0 $spacer * 2 0);
      text-transform: uppercase;
      color: $color-text--80;
    }
  }

  &__newsletter {
    flex: 1;
  }
}

// Responsive FOOTER styles

.fp-container__desktop {
  @media(max-width: 1150px) {
    display: none;
  }
}

.fp-container__responsive {
  display: none;
  @media(max-width: 1150px) {
    display: block;
  }

  .fp-footer__header {
    figure {
      @include size(100%, 25px);
    }
  }

  .fp-footer__links-container {
    @include flex(flex-start, stretch, column, $wrap: initial);
  }

  .fp-accordion {
    border-top: $border-light-gray;

    &__header {
      @include flex(flex-start, center, null);
      @include size(100%, $spacer * 11);
      @include margins(0);
    }

    &__content {
      @include padding(0 0 $spacer * 4 0);
      display: none;

      &.open {
        display: block;
      }
    }

    ul {
      @include grid(repeat(2, max-content), null, auto);
      grid-column-gap: $spacer * 6;
      grid-row-gap: $spacer * 2;

      li {
        @include margins(0);
      }
    }
  }
}

// Cookie consent
.cookie-consent {
  @include flex(center, center, null);
  @include padding(8px 0px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 500;

  background-color: $white;
  border-top: $border-light-gray;
  p {
    margin-right: 16px;
  }
  .fp-button {
    @include padding(8px 24px);
  }

  @media (max-width: $mobile) {
    @include flex(null, null, column);
    @include padding(8px);
    p {
      margin-bottom: 16px;
      margin-right: 0px;
    }
  }
}

.hide {
  display: none;
}
