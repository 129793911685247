
// This file contains all the SUBSCRIPTION BANNER styles
// This file is imported into the _components.scss file


.fp-subscriptions-banner {
  @include margins($spacer / 2 0);
  @include padding($spacer * 3 0);
  background: $accent-blue-light;
  text-align: center;

  a {
    color: $color-text--80;

    &:hover {
      text-decoration: underline;
    }
  }
}
