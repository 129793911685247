// It is imported in the _layout.scss file

.fp-container {
  @include size($container-width, null, $maxw: $container-max-width);
  @include margins(0 auto);
  @media (max-width: $tablet){
    @include size(auto, null, $maxw: $container-max-width);
    @include margins(0 auto);
  }
}

.fp-page-content {
  flex: 1;
}

.fp-container__extra-large {
  @include size(100%, null, $maxw: $container-max-width);
  @include margins(0 auto);
  @media (max-width: 700px) {
    @include size(100%, null, $maxw: $container-max-width);
  }
}
