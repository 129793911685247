// VK variant for the header

.fp-header-responsive__top {
  &__logo {
    @media (max-width: $mobile) {
      img {
        height: 20px;
        max-width: 190px;
      }
    }
  }
}