// This file contains all the SIDEBAR MENU styles
// This file is imported into the _components.scss file

.fp-sidebar-menu {
  @include size(400px, 100vh, $minh: 100vh);
  @include padding($spacer * 6 $spacer * 4);
  @include translate(-110%, 0);
  background: $white;
  bottom: 0;
  box-shadow: 5px 0 10px rgba($black, 0.15);
  left: 0;
  position: fixed;
  top: 0;
  z-index: 200;
  overflow-y: scroll;

  opacity: 0;

  @media (max-width: $mobile) {
    @include size(100vw, 100%, $minh: 100%);
    @include padding($spacer * 4);
    padding-bottom: $spacer * 10;
  }

  &.open {
    @include translate(0, 0);
    @include transition(0.3s, $expo);
    opacity: 1;
  }

  &.closed {
    @include translate(-110%, 0);
    @include transition(0.3s, $expo);
    opacity: 0;
  }

  &__header {
    @include flex(space-between, center, null);
    @include margins(0 0 $spacer * 6 0);

    &--logo {
      @include size(auto, 25px);

      img {
        @include size(auto, 100%);
      }
    }
    &--close {
      cursor: pointer;
    }
  }

  &__search-wrapper {
    @include flex(null, center, null);
    position: relative;
  }

  &__search {
    @include margins(0 $spacer * 2 0 0);
    position: absolute;
    left: $spacer * 2;
    top: 50%;
    transform: translateY(-50%);
    &__input {
      @include font($text-base-size, $font-regular, normal, $font-secondary);
      @include size(100%, $spacer * 12);
      @include padding(0 0 0 $spacer * 10);
      border-radius: $spacer;
      background: transparent;
      border: 1px solid $color-blue--50;
      color: $color-text--80;

      &::placeholder {
        @include font($text-base-size, $font-regular, normal, $font-secondary);
        color: $color-text--50;
        @media (max-width: $mobile) {
          @include font($text-base-size, $font-regular, normal);
        }
      }
      &:focus {
        outline-color: $color-primary;
      }
    }

    &__close {
      @include flex(center, center, null);
      @include transition(0.1s, linear);
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: $spacer * 2;
      transform: translateY(-50%);

      opacity: 0;
      visibility: hidden;
      user-select: none;
      &.visible {
        opacity: 1;
        visibility: visible;
        user-select: auto;
      }
    }
  }

  li.fp-hidden {
    display: none;
  }

  &__main-nav {
    .fp-sidebar-menu__nav--header {
      h4 {
        color: $color-text--80;
        a {
          color: $color-text--80;
          &:visited {
            color: $color-text--80;
          }
        }
      }
      span {
        color: $color-text--80;
        a {
          color: $color-text--80;
          &:visited {
            color: $color-text--80;
          }
        }
      }
    }

    .fp-sidebar-menu__nav--list {
      @include margins(0 0 $spacer * 6 0);
      @include flex($wrap: wrap);
      &:last-of-type {
        @include margins(0);
      }
    }
    .fp-sidebar-menu__nav--item {
      @include margins(0 $spacer / 2 $spacer * 2 0);
      display: inline-flex;
      &:before {
        content: '•';
        @include margins(0 $spacer);
        color: $color-text--50;
      }
      &:first-of-type {
        &:before {
          content: '';
          @include margins(0);
        }
      }
    }
  }

  &__nav {
    @include margins($spacer * 6 0 0 0);
    @include padding(0 0 $spacer * 6 0);
    border-bottom: $border-light-gray;

    &--header {
      @include margins(0 0 $spacer * 4 0);

      h4 {
        @include font($text-sm, $font-bold, normal);
        color: $color-text--80;
        text-transform: uppercase;
      }
    }

    &--item {
      @include margins(0 0 $spacer * 4 0);

      &:last-child {
        @include margins(0);
      }

      a {
        color: $color-text--50;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__social-media {
    @include padding($spacer * 8 0 0);
  }
}

.fp-sidebar-menu__overlay {
  @include size(100vw, 100vh);
  @include transition(0.2s, $expo);
  background: rgba($black, 0.5);
  bottom: 0;
  left: 0;

  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: -1;

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 180;
  }

  &.closed {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
  @media (max-width: $mobile) {
    display: none;
  }
}
.fp-sidebar-menu__nav_mobile {
  display: none;
  @media screen and (max-width: $mobile) {
    display: block;
  }
}

.fp-sidebar-menu__nav_desktop {
  @media screen and (max-width: $mobile) {
    display: none;
  }
}