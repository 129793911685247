// This file contains all the BREADCRUMBS styles
// This file is imported into the _shell.scss file

.fp-breadcrumbs {
 // @include flex(null, center, row, wrap);
  @include flex(null, center, row);
  @include margins(0 0 $spacer-v-l 0);
  overflow: hidden;

  @media(max-width: $tablet) {
    padding-top: 16px;
  }

  @media(max-width: $mobile) {
    margin-left: -$spacer*2;
    margin-right: -$spacer*2;
  }

  &__article {
    @media(max-width: $mobile) {
      li {
        &:last-child {
          display: none;
        }
      }
    }
  }

  &__item {
    @include margins(0 $spacer-h-xxxs 0 0);

    p, span, a, h1 {
      color: $color-text;
      @include font($text-sm, $text-regular, normal, $lh: $lh-m);
      @include elipsis(1);
    }

    a {
      opacity: .7;

      &:visited {
        color: $color-text;
      }

      &:hover {
        color: $color-primary;
        opacity: 1;
        text-decoration: underline;
      }
    }
  }

  &__arrow {
    @include margins(0 $spacer-h-xxxs 0 0);
    color: $color-text;
  }
}
