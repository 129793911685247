.m-ad-unit-1 {
  overflow: hidden;
  max-width: 1380px;
  width: 97%;
  margin: 0 auto;
}

.m-ad-unit-2 {
  margin: 2% auto;
  max-width: 800px;
  width: 100%;
}

#ad-top-banner {
  margin: 0 auto;
  min-height: 250px;
}

.fp-article-block {
  #content-ad,
  #content-ad-s {
    margin: 0 0 $spacer * 4 $spacer * 4 !important;
    float: right;

    @media screen and (max-width: 1000px) {
      float: none;
      width: 100%;
      @include space(margin, only-vertical, $spacer * 4);
    }
  }
}

.relative-container {
  position: relative;
}

.page-holder {
  max-width: 1396px;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  position: relative;
  @media screen and (max-width: 1380px) {
    max-width: 1360px;
  }
}

.ad-skyscraper {
  position: fixed;
  margin-left: 1046px;
  @media screen and (max-width: 1380px) {
    margin-left: 1010px;
  }
  display: flex;
  top: 160px;

  @media screen and (max-width: 1360px) {
    margin-left: initial;
    right: 0;
  }
  @media screen and (max-width: 1052px) {
    margin-left: 792px;
    right: initial;
  }
}

.page-holder > .fp-page-content {
  @include space(padding, horizontal, $spacer * 2);
  width: calc(100% - 360px);
  @media screen and (max-width: 1380px) {
    @include space(padding, horizontal, 0);

  }
  @media screen and (max-width: 1052px) {
    min-width: 792px;
  }
  @media screen and (max-width: 792px) {
    min-width: 100%;
  }
}

#ad-top-banner-s {
  margin-bottom: 16px;
}

#ad-out-of-page-s {
  margin-bottom: 16px;
}

#nativendo-artikel {
  margin-bottom: 16px;
}

#ad-out-of-page-s-mobile {
  bottom: 0;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 999;
}

.ad-poster-s {
  @include space(margin, only-vertical, $spacer * 8 $spacer * 2);
  width: 100%;
  text-align: center;
}

#anzeigenkennung {
  display: none;
}


//duMont
#ad-top-banner-mobile {
  min-height: 250px;
}

.fp-article-body {
  margin: 0 0 16px 0 !important;
}
