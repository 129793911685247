.paywall-overlay {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 250, 0) 100%
  );
  width: 100%;
  height: 150px;
  margin-top: -150px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
}

.paywall-dialog {
  box-sizing: border-box;
  height: auto;
  max-width: 800px;
  color: white;
  margin: 0 auto;
  @include space(margin, bottom, $spacer * 8);

  @media (max-width: 700px) {
    @include space(margin, bottom, $spacer * 4);
  }

  &__paid {
    background: #304fa8;
  }

  &.hidden {
    display: none !important;
  }
}

.paywall-top {
  display: block;
}
.paywall-bottom {
  @include space(padding, all, $spacer * 8 $spacer * 4 );
  background-color: $color-secondary;
}

.paywall-header-text {
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  font-family: $font-secondary;
}

.paywall-description {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  margin-top: 8px;
  text-align: center;
  font-family: $font-secondary;
  padding-bottom: 25px;

  a {
    color: #788995;
    text-decoration: underline;
    font-weight: bold;
  }
}

.paywall-button {
  background-color: white;
  color: $color-primary;
  @include space(padding, all, 12px $spacer * 4);
  text-align: center;
  text-align: center;
  width: 380px;
  max-width: 100%;
  margin: 0 auto;
  font-family: $font-secondary;
  font-weight: $weight-bold;
  border-radius: 4px;
  text-transform: uppercase;

}

.paywall-login-link {
  color: white;
  text-decoration: underline;
  display: flex;
  font-style: normal;
  font-weight: normal;
  justify-content: center;
  line-height: 20px;
  margin-top: 16px;
  font-family: $font-secondary;
  a {
    color: white;
  }
  span {
    font-weight: bold;
  }
}
