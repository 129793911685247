// This file contains all the HEADER SEARCH styles
// This file is imported into the _shell.scss file

.fp-main-header-search {
  @include size(100%, $spacer * 15 + 4);
  @include translate(0, -110%);

  background: $white;
  border-bottom: $border-light-gray;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;

  opacity: 0;

  @media (max-width: $mobile) {
    @include size(null, $spacer * 11, null);
  }

  &.open {
    @include translate(0, 0);
    @include transition(0.3s, $expo);
    opacity: 1;
  }

  &.closed {
    @include translate(0, -110%);
    @include transition(0.3s, $expo);
    opacity: 0;
  }

  &__container {
    @include flex(space-between, center, null);
    @include size(null, $spacer * 15 + 3, null);
    @include space(padding, horizontal, $spacer * 2);
    background: $white;
    position: relative;
    z-index: 49;

    @media (max-width: $mobile) {
      @include size(null, $spacer * 11 - 1, null);
    }
  }

  &__input-wrapper {
    @include flex(flex-start, center, null);
  }

  &__search {
    user-select: none;

    path {
      stroke: $gray;
    }
  }

  &__input-wrapper {
    flex: 1;

    input {
      @include font($text-base-size, $weight-medium, normal, $font-secondary);
      @include margins(0 0 0 $spacer * 2);
      @include size(100%, $spacer * 10);
      @include space(padding, all, $spacer * 4);
      background: transparent;
      border: 0;
      color: $color-text;
      border: 1px solid $color-blue--50;
      border-radius: 4px;

      @media (max-width: $mobile) {
        @include font($text-base-size, $weight-medium,  normal);
        @include size(100%, $spacer * 8);
      }

      &::placeholder {
        @include font($text-base-size, $font-bold, normal, $font-secondary);
        color: $light-gray;

        @media (max-width: $mobile) {
          @include font($text-base-size, $font-bold, normal);
        }
      }
    }
  }

  &__close {
    @include flex(center, center, null);
    @include margins(0 0 0 $spacer * 2);
    cursor: pointer;

    @media (max-width: $mobile) {
      @include margins(0 0 0 $spacer);
    }

    path {
      stroke: $gray;
    }
  }

  &__dropdown {
    @include padding($spacer * 2 0);
    @include margins(1px 0 0 0);
    @include translate(0, -100%);
    background: $white;
    box-shadow: 0 2px 6px rgba($black, 0.15);
    opacity: 0;
    overflow-y: scroll;
    transition: opacity 0.1s ease, transform 0.2s $expo;

    &.open {
      @include translate(0, 0);
      opacity: 1;
    }

    @media (max-width: 900px) {
      @include size(null, 100vh);
    }
  }

  &__no-publications {
    @include flex(flex-start, center, null);
    @include margins($spacer * 2 0);
    margin-left: -#{$spacer * 2};

    @media (max-width: 900px) {
      @include padding(0 0 0 $spacer - 2);
      margin-left: 0;
    }

    svg {
      path {
        stroke: $gray;
      }
    }

    p {
      @include margins(0 0 0 $spacer * 2);
      color: $gray;
    }
  }

  &__error {
    @include margins($spacer * 2 0);

    p {
      color: red;
    }
  }
}

// Styles for the overlay

.fp-main-header-search__overlay {
  @include size(100vw, 100vh);
  @include transition(0.4s, ease);
  background: rgba($black, 0.5);
  bottom: 0;
  left: 0;

  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 180;
  }

  &.closed {
    opacity: 0;
    visibility: hidden;
  }
}
