// Styles for the search results page

.fp-search-results {
  .fp-no-results {
    @include padding($spacer * 2 0);
    @include margins(0 0 $spacer * 8 0);
    border-bottom: $border-primary-thin;

    @media (max-width: $mobile) {
      display: none;
    }

    &__text {
      @include flex(null, flex-end, null);
      @include font($text-xxxl, $weight-bold, normal, $font-secondary);
      color: $color-primary;
      line-height: 1;
      justify-content: center;
      text-align: center;

      @media (max-width: $mobile) {
        align-items: center;
        font-size: $text-md;

        &.text-24--bold {
          font-size: $text-md;
        }
      }

      a {
        color: inherit;

        &:visited {
          color: $color-primary;
        }
      }
    }
  }
}
.search-page-search-field {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  .search-page-input-field {
    font-family: $font-secondary;
    font-size: inherit;
    font-style: normal;
    font-weight: $font-bold;
    height: 40px;
    width: 99%;
    border: 1px solid $color-borders;
    border-radius: 4px;
    padding: 0 0 0 16px;
  }
  .search-page-input-field::placeholder {
    color: $color-primary--50;
  }
  .search-page-button {
    border: 1px solid $color-primary;
    border-radius: 0px 6px 6px 0px;
    background-color: $color-primary;
    color: $color-white;
    font-family: $font-secondary;
    font-size: $text-base-size;
    font-weight: $font-bold;
    height: 40px;
    width: 120px;
    position: absolute;
    right: 0;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

input.search-page-input-field:focus {
  outline-color: $color-primary;
}