// This file contains the styles for the ERROR PAGE
// It is imported in the _layout.scss file

.fp-error {
  @include flex(center, center, null);
  width: 100%;
  min-height: calc(100vh - 167px - 314px - 124px);
  &__404,
  &__500 {
    @include flex(center, center, null);
    width: 100%;
  }

  &__404 {
    background-color: #18417c0d;
    @media screen and (max-width: $mobile) {
      @include space(padding, all, 0 $spacer * 4);
    }
  }

  &__content {
    @include space(padding, all, 64px 0);
    @include flex(flex-start, center, column);

    @media (max-width: $tablet) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__image {
    @include size(250px, 250px);
    @include flex(center, center, null);
    @include margins(0 $spacer * 10 0 0);

    @media (max-width: $tablet) {
      @include size(180px, 180px);
      @include margins(0 0 $spacer * 4 0);
    }

    img {
      @include size(100%, 100%);
      object-fit: contain;
      object-position: center;
    }
  }

  &__title {
    @include margins(0 0 $spacer * 2 0);
    @media (max-width: $tablet) {
      font-size: $text-xxl;
    }
  }

  h1 {
    @include font(32px, $weight-bold, normal);
    line-height: $line-height-xxxl;
    @media (max-width: $tablet) {
      font-size: $text-xxl;
    }
  }

  &__message {
    @include font(22px, $weight-bold, normal, $font-secondary);
    line-height: normal;
    @include space(margin, only-vertical, $spacer * 2 $spacer * 4);

    @media (max-width: $tablet) {
      font-size: $text-base-size;
      line-height: $line-height-base;
    }
  }
  .fp-error__message_p {
    @include space(margin, only-vertical, $spacer * 4 48px);
    @include font($text-lg, $weight-regular, normal, $font-secondary);
    line-height: $line-height-lg;
    @media screen and (max-width: $mobile) {
      font-size: $text-md;
    }
  }
}

.error-full.fp-page-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
 background-color: $color-primary;
  .fp-error__500 {
    color: white;
  }
  .fp-error__text{
    text-align: center;
  }
  h1 {
    @include font(32px, $weight-bold, normal);
    line-height: $line-height-xxxl;
    color: white;
    @media (max-width: $tablet) {
      font-size: $text-xxl;
    }
  }

  .fp-error__message {
    @include font(22px, $weight-bold, normal, $font-secondary);
    line-height: normal;
    @include space(margin, only-vertical, $spacer * 2 $spacer * 4);
    color: white;
    @media (max-width: $tablet) {
      font-size: $text-base-size;
    }
  }
}
