// This file contains the styles for the layout GRID iva:done
// It is imported in the _layout.scss file
// Classes start with "fp" which stands for Forward Publishing

.center {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  align-items: center;
}

.fp-grid-row {
  @include size(100%, null);
  @include grid(repeat(12, 1fr), $spacer * 4, null);
}

.fp-page__body {
  @include padding(0 $body-margin);
  > :first-child {
    padding-top: 0px;
    border-top: 0px;
  }
  @media screen and (max-width: 1380px) {
    @include space(padding, horizontal, 34px);
  }
  @media (max-width: $tablet) {
    @include padding(0);
  }
}

.fp-page__body-margin {
  @include space(margin, left, $body-negative-margin);
  @include space(margin, right, $body-negative-margin);
  @media (max-width: 700px) {
    @include space(margin, left, 0);
    @include space(margin, right, 0);
    .fp-article__image {
      margin-left: calc(-100vw / 2 + (100vw - 32px) / 2);
      margin-right: calc(-100vw / 2 + (100vw - 32px) / 2);
      max-width: 100vw;
      width: 100vw;
    }
  }
}

// Add the class to the article tag if you need to have image that is full container width wide

.full-width-image .fp-article__image {
  @include space(margin, left, $body-negative-margin);
  @include space(margin, right, $body-negative-margin);
  max-width: 100vw;
  // body white space * 2
  width: calc(100% + 100px);

  @media screen and (max-width: $tablet) {
    // body white space * 2
    margin-left: calc(-100vw / 2 + (100vw - 32px) / 2);
    margin-right: calc(-100vw / 2 + (100vw - 32px) / 2);
    width: calc(100% + 32px);
  }
}