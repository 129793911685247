.fp-section-topic {
  margin-bottom: 64px;
  margin-top: 32px;

  #topicList {
    margin-top: 0px;
    width: auto;
    display: inline-flex;
    min-width: 100%;

    .fp-letter a {
      display: block;
    }

    .is-fixed {
      position: fixed;
      width: 100%;
      transform: translateY(0%);
    }
  }

  .fp-letters {
    margin-top: 64px;

    .fp-letter a {
      display: block;
    }

    .fp-topic-group {
      @include space(margin, top, $spacer * 8);
      border-bottom: 1px solid #c4c4c4;
    }

    &-top {
      display: flex;
      max-width: 1000px;
      width: 100%;
      background: white;
      padding: 16px 0 8px 3px;
      z-index: 9;
      @media screen and (max-width: $tablet) {
        width: 100%;
      }

      //box-shadow: lightgray 0px 14px 16px 1px;
      border-bottom: 1px solid $color-primary;
      &-container {
        height: 57px;

        @media screen and (max-width: $tablet) {
          overflow: hidden;
          overflow-x: auto;
        }
      }
    }
  }

  .is-fixed {
    position: fixed;
    width: 100%;
    transform: translateY(0%);
  }
  .fp-topic-group {
    &_letter {
      padding: 0;
      font-size: 22px;
      font-weight: bold;
      font-family: 'Muli';
      color: #17417c;
    }
    &_container {
      @include space(padding, all, $spacer * 2);
      display: flex;
      flex-wrap: wrap;
    }
  }
  .fp-topic_name {
    @include space(margin, all, $spacer * 2);
    border: 1px solid #c4c4c4;
    display: inline-flex;
    padding: 6px 12px;
    border-radius: 9px;
    font-size: $text-base-size;
    line-height: $line-height-base;
    font-family: $font-secondary;
    font-weight: bold;

    a {
      color: $color-primary;
      opacity: 0.8;
    }
  }

  a {
    border: none;
    padding: 4px 8px;
    margin: 0 5px;
    text-decoration: none;
    background: transparent;
    font-family: sans-serif;
    font-size: $text-sm;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-weight: bold;
  }
  .fp-letters-top a {
    color: $color-primary;
  }

  a:hover{
    color: #0053ba;
  }

  a:active {
    transform: scale(0.99);
  }
}
