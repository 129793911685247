
// This file contains all the HEADER styles
// This file is imported into the _components.scss file
.fp-header__sticky-wrapper {
  //height: 102px;
}
.fp-header-responsive-wrapper {
  display: none;
  @media (max-width: $tablet) {
    display: block;
    height: 100px;
  }
}
.fp-main-header {
  .fp-header {
    @include space(padding, bottom, $spacer * 4);
    @include flex(null, null, column);
    background: $white;

    @media (max-width: $tablet) {
      display: none;
    }

    &__sticky {
      @include size($container-width, null, $maxw: $container-max-width);
      //  @include padding(53px 0 0 0);
      position: static;
      // transform: translate(0%, 0);
      z-index: 101;
      background: $white;
      transition: all 0.4s ease-in-out;

      &.scroll-down {
        //   @include transition(0.4s, ease-out);
        transform: translateY(-100%);
        position: fixed;
        top: 0;
      }

      &.scroll-up {
        // @include transition(0.4s, ease-in);
        transform: translateY(0);
        position: fixed;
        top: 0;
      }

      &.initial-scroll-down {
        position: fixed;
        top: -100%;
        &.scroll-down {
          top: 0;
        }
      }
    }
  }

  &__top {
    @include flex(space-between, center, null);
    @include space(padding, top, $spacer * 5);
    border-bottom: $border-light-gray;
    position: relative;
    z-index: 50;
    background: $white;
    min-height: 53px;
  }

  &__links {
    @include flex(null, center, null);
    @include padding(0 0 $spacer * 2 $spacer * 3);
    border-left: $border-light-gray;
    margin-left: auto;
    ul {
      @include flex(null, center, null);
      @include margins(0 $spacer * 4 0 0);
    }

    li {
      @include margins(0 $spacer * 4 0 0);
      white-space: nowrap;
      &:last-child {
        @include margins(0);
      }
    }

    a {
      color: $color-text--80;
      user-select: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__middle {
    @include flex(null, center, null);
    @include space(padding, bottom, $spacer * 2);
    min-height: 65px;
  }

  &__menu-search-wrapper {
    width: 25%;
    @include flex(null, center, null);

    a {
      cursor: pointer;
      display: inline-block;
    }

    &--search {
      @include margins(0 0 0 $spacer * 6);
    }
  }

  &__logo {
    width: 50%;
    text-align: center;
    a {
      display: inline-block;
      user-select: none;
    }
  }

  &__weather-widget {
    @include flex(null, flex-end, column);
    width: 25%;

    &--top {
      @include flex(null, center, null);
      position: relative;
      user-select: none;
      &:hover {
        .fp-main-header__weather-widget--tooltip {
          opacity: 1;
          transform: translate(0, -50%);
        }
      }

      .weather-icon-img:not(img) {
        position: relative;
      }

      aside {
        @include flex(null, center, null);
        @include space(margin, left, $spacer);
        span {
          @include font($text-base-size, $font-bold, normal, $font-secondary);
          color: $color-text--80;
          display: inline-flex;
          user-select: none;
        }
        span:nth-child(2) {
          margin: 0 3px;
        }
      }
    }

    .unknown-weather img {
      display: none;
    }

    &--tooltip {
      @include padding($spacer $spacer * 2);
      @include font($text-xs, $font-regular, normal, $family: $font-secondary);
      @include transition(0.25s, $expo);
      text-align: left;
      white-space: nowrap;
      display: inline-block;
      user-select: none;
      background: $black;
      color: $white;
      text-transform: capitalize;
      border-radius: $spacer / 2;
      position: absolute;
      top: 50%;
      right: 100%;
      opacity: 0;
      transform: translate(-$spacer * 2, -50%);
      &:before {
        @include size(0px, 0px);
        content: '';
        border-top: $spacer solid transparent;
        border-bottom: $spacer solid transparent;
        border-left: $spacer solid $black;
        position: absolute;
        top: 50%;
        right: -$spacer;
        transform: translateY(-50%);
      }
    }

    &--bottom {
      span {
        @include font($text-xs, $font-bold, normal, $font-secondary);
        text-align: right;
        color: $color-text;
        user-select: none;
      }
    }
  }
  .weather-icon-img {
    width: 41px;
  }

  &__middle,
  &__top {
    @media screen and (max-width: 1380px) {
      @include space(padding, right, $spacer * 4);
      @include space(padding, left, $spacer * 4);
    }
  }
}

// Responsive
.fp-header-responsive {
  display: none;

  @media (max-width: $tablet) {
    @include size(100%, null);
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 90;
    background: $white;
    @include transition(0.4s, ease-in-out);
  }

  &.scroll-down {
    transform: translate(0, -100%);
  }

  &.scroll-up {
    transform: translate(0, 0);
  }

  &__top {
    @include flex(space-between, center, null);
    @include padding($spacer * 4 $spacer * 2 $spacer * 2 $spacer * 2);
    position: relative;

    .fp-main-header__menu-search-wrapper--search,
    .fp-main-header__menu-search-wrapper--burger {
      @media (max-width: $mobile) {
        svg {
          path {
            stroke-width: 1.5px;
          }
        }
      }
    }

    &--menu {
      position: relative;
    }

    &--sao {
      color: #e5732e;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      margin-left: $spacer;
      @-moz-document url-prefix() {
        margin-left: $spacer * 6;
      }
    }

    &__logo {
      @media (max-width: $mobile) {
        a {
          display: flex;
        }
        img {
          height: 30px;
          max-width: 190px;
        }
      }
    }
  }

  &__bottom {
    @include flex(space-between, center, null);
    @include padding($spacer * 2 0);
    position: relative;

    .fp-main-nav {
      @include padding(0);
      overflow: hidden;

      &__list {
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

.fp-main-nav {
  position: relative;
}

.nav__pointer {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: $color-primary;
  height: 3px;
  transition: transform 0.25s ease-in-out;
  border-radius: 3px;
  will-change: transform;
  backface-visibility: hidden;
  transition: width 0.5s ease-in;
  transition: transform3d 0.3s ease-out;
}

.nav__list {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

// LogIn/LogOut dropdown

.fp-main-header__links--login-dropdown {
  position: absolute;
  min-width: 15ch;
  right: 0;
  top: calc(100% - 0.25rem);
  transition: 280ms all 120ms ease-out;
  transform: rotateX(-90deg) translateX(0);
  transform-origin: top center;
  visibility: hidden;
  opacity: 0.3;
  padding: 0.5em 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0.15em 0.25em rgba(black, 0.25);

  li {
    margin-bottom: 0;
    display: block;
  }
  li a {
    text-decoration: none;
    font-size: 15px;
    color: black;
    font-family: 'Muli', sans-serif;
    font-weight: 400;
    padding: 16px 20px;
    box-sizing: border-box;
    display: inline-flex;
    width: 100%;
    opacity: 0;
    transition: 280ms all 120ms ease-out;
    &:hover {
      background-color: #17417c12;
    }
  }
}

.fp-hidden {
  display: none;
}

.fp-main-header__links-wrapper {
  @include space(padding, bottom, $spacer * 2);
  position: relative;
}

.fp-main-header__links-wrapper {
  &:hover,
  &:focus-within {
    .fp-main-header__links--login-dropdown {
      opacity: 1;
      transform: rotateX(0) translateX(0);
      visibility: visible;
      a {
        opacity: 1;
      }
    }
  }
}

#fp-main-header__links-wrapper-mobile .fp-main-header__links--login-dropdown {
  z-index: 3;
}
.fp-main-header__links--login-dropdown.open {
  opacity: 1;
  transform: rotateX(0) translateX(0);
  visibility: visible;
  a {
    opacity: 1;
  }
}
