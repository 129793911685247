// This file contains all the REGION styles
// This file is imported into the _shell.scss file

.fp-regions {
  @include padding($spacer * 4 $spacer * 6);
  background: $color-secondary;

  @media screen and (max-width: $tablet) {
    margin-left: -16px;
    margin-right: -16px;
  }
  .fp-select-wrapper {
    @include padding(0);
    align-items: center;

    @media (max-width: 700px) {
      flex-direction: column;
    }
  }

  .fp-select {
    @include size(250px, null, $minw: initial);
    background: $white;
    border-radius: $spacer;
  }

  .fp-select__styled {
    @include padding($spacer * 2 $spacer * 6);
  }

  .fp-select-label {
    padding-right: $spacer * 9;
    color: $white;

    @media (max-width: 700px) {
      @include padding(0 0 $spacer * 2 0);
    }
  }

  .fp-select__styled--display {
    color: $color-secondary;
  }

  .fp-select__styled--options {
    @include size(auto, null);
    @include padding($spacer * 4 $spacer * 6);

    @media (max-width: $tablet) {
      @include translate(-50%, 0);
      left: 50%;
    }

    ul {
      @include grid(repeat(3, 1fr), $spacer * 2, auto);
      grid-column-gap: $spacer * 10;
      @media (max-width: $tablet) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: $spacer * 4;
      }
    }

    li {
      @include padding($spacer * 2);
      color: $gray;
      white-space: nowrap;
      border-radius: $spacer;
      &:hover {
        background: $lighter-gray;
        svg {
          path {
            stroke: $color-primary;
          }
        }
      }
    }

    p {
      font-size: $text-sm;
      font-weight: $font-semibold;
      white-space: nowrap;
    }
  }

  svg {
    path {
      stroke: $color-secondary;
    }
  }

  .fp-select__border-bottom {
    border: 0;
  }
}
