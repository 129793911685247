// This file is a reference point for all the VARIABLES
// Each variable is commented and has basic instructions
// It is imported in the _basics.scss file

// Basic unit variables
$spacer: 4px;

// Transition variables
$expo: cubic-bezier(0.87, 0, 0.13, 1);

// Color variables

// Black, white, gray
$white: #fff;
$black: #000;

$dark-gray: #636363;
$gray: #9c9c9c;
$light-gray: #d0d0d0;
$lighter-gray: #f2f2f2;

// Social media
$facebook: #1877f2;
$twitter: #1da1f2;
$instagram: #c32aa3;

// Accent colors
$accent-magenta: #e6007e;
$accent-magenta-light: #f5b5d2;

$accent-blue: #009fe3;
$accent-blue-light: #83d0f5;

$accent-yellow: #f59d0f;
$accent-yellow-light: #fcca83;

$accent-purple: #95569e;
$accent-purple-light: #daacd1;

$accent-green: #00ab97;
$accent-green-light: #96d3dd;

// Colors
$color-primary: #16417c;
$color-secondary: #2667c5;
$color-primary-light: #3e8df7;
$color-primary-light-2: #198cff;
$color-text: #2a2828;
$color-primary--50: #8aa0bd;
$color-primary--30: #b9c6d8;
$color-text--80: #2a2828;
$color-text--50: #949393;
$color-borders: $color-primary--30;
$color-white: #fff;
$color-blue--50: #B9C6D8;


// Border variables
$border-black: 1px solid $black;
$border-dark-gray: 1px solid $dark-gray;
$border-gray: 1px solid $gray;
$border-light-gray: 1px solid $light-gray;
$border-lighter-gray: 1px solid $lighter-gray;
$border-primary: 2px solid $color-primary;
$border-primary-thin: 0.5px solid $color-primary;

$text-base-size: 16px;

// Font weights
$font-extralight: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

// Line Heght Variables
$lh-xxxl: 3.173rem;
$lh-xxl: 2.539rem;
$lh-xl: 2.1rem;
$lh-l: 1.875rem;
$lh-m: 1.4rem;
$lh-s: 1.125rem;

// Space Variables

// Vertical Variables
$spacer-v-xxxs: 0.5rem;
$spacer-v-xxs: 0.75rem;
$spacer-v-xs: 1rem;
$spacer-v-s: 1.25rem;
$spacer-v-m: 1.5rem;
$spacer-v-l: 1.75rem;
$spacer-v-xl: 2rem;
$spacer-v-xxl: 2.5rem;
$spacer-v-xxxl: 3rem;

// Horizontal Variables
$spacer-h-xxxs: 0.5rem;
$spacer-h-xxs: 0.75rem;
$spacer-h-xs: 1rem;
$spacer-h-s: 1.25rem;
$spacer-h-m: 1.5rem;

// Responsive variables
$mobile: 550px;
$tablet: 900px;

// Container variables
$container-max-width: 1000px;
$body-margin: 50px;
$body-negative-margin: -$body-margin;
$container-width: 100%;

// Container variables
$container-max-width: 1000px;
$body-margin: 50px;
$body-negative-margin: - $body-margin;
$container-width: 61.625rem;
$container-width-tablet: 47.25rem;

// Typography Variables
$font-primary: 'Lato', sans-serif;

$text-s: 0.8125rem;
$text-m: 1rem;
$text-l: 1.25rem;
$text-xl: 1.563rem;
$text-xxl: 1.953rem;
$text-xxxl: 2.441rem;

$text-regular: 400;
$text-bold: 700;

// Article variables
$manuscript-width: 800px;

$font-primary: 'Domine', serif;
$font-secondary: 'Muli', sans-serif;
