
// This file contains all the TOP NAVIGATION styles
// This file is imported into the _components.scss file

.fp-top-nav {
  @include size(null, $spacer * 6);
  @include flex(null, center, null);
  @include space(margin, right, $spacer * 2);
  @include space(margin, bottom, $spacer * 2);

  overflow: hidden;
  position: relative;
  width: 100%;
  flex: 1;
  @media (max-width: $tablet) {
    display: none;
  }

  &__list {
    @include flex(flex-start, center, null);
    @include transition(0.2s, ease);
  }

  &__list-item {
    @include padding(0 $spacer * 2);
    @include flex(null, center, null);
    user-select: none;
    white-space: nowrap;

    a {
      color: $color-text--50;

      &:hover {
        text-decoration: underline;
      }
    }

    &:first-child {
      @include padding(0 $spacer * 2 0 0);
    }
  }

  &__arrow-container {
    @include flex(null, center, null);
    @include size(null, 100%);
    box-shadow: -$spacer * 3 0 6px rgba($white, 0.95);
    display: none;
    position: absolute;
    right: 0;
    z-index: 10;
  }

  &__left-arrow,
  &__right-arrow {
    @include size($spacer * 6, $spacer * 6);
    @include flex(center, center, null);
    @include transition(0.2s, $expo);
    background: $white;
    border-radius: $spacer;
    cursor: pointer;

    &:hover {
      background: $lighter-gray;
    }
  }
}

