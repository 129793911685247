// This file contains all the TYPOGRAPHY styles for the project
// It is imported in the _basics.scss file -delivery

$text-base-size: 16px;
$line-height-base: 22px;


// Font Size
$text-xs: 12px;
$text-sm: 14px;
$text-md: 18px;
$text-lg: 20px;
$text-xl: 22px;
$text-xxl: 24px;
$text-xxxl: 26px;

$text-teaser-index: 40px;

// Line Height
$line-height-xs: 18px;
$line-height-sm: 20px;
$line-height-md: 24px;
$line-height-lg: 27px;
$line-height-xl: 30px;
$line-height-xxl: 33px;
$line-height-xxxl: 36px;

// Font Weight
$weight-thin: 100;
$weight-extraLight: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semiBold: 600;
$weight-bold: 700;
$weight-extraBold: 800;
$weight-black: 900;



