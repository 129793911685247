// This file contains the styles for the SOCIAL MEDIA
// It is imported in the _components.scss file

.fp-social-media {
  @include flex(null, center, null);
  a {
    @include margins(0 $spacer * 8 0 0);
    &:last-child {
      @include margins(0);
    }
  }
  path {
    @include transition(0.15s, ease);
  }

  &__twitter {
    &:hover {
      path {
        stroke: $twitter;
      }
    }
  }

  &__instagram {
    &:hover {
      path {
        stroke: $instagram;
      }
    }
  }

  &__facebook {
    &:hover {
      path {
        stroke: $facebook;
      }
    }
  }
}