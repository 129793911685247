
// This file contains all the COPYRIGHT styles
// This file is imported into the _components.scss file

.fp-copyright {
  @include size(100%, null);
  @include padding($spacer * 8 0 $spacer * 4 0);

  .fp-container__small {
    display: grid;
    grid-auto-rows: auto;
    grid-gap: $spacer * 2;
  }

  &__links {
    @include flex(center, flex-start, null, $wrap: wrap);
    p {
      @include margins(0 $spacer * 4 $spacer * 4 0);
    }
    a {
      @include margins(0 $spacer * 4 $spacer * 4 0);
      @include transition(0.15s, ease);
      white-space: nowrap;
      color: $color-text--50;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
